var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{ref:"modal",attrs:{"id":"edit-asset-pool-distribution","title":"Edit Asset Pool Distribution","ok-title":"Save","cancel-disabled":_vm.disableConfirmButtons,"ok-disabled":_vm.disableConfirmButtons,"no-close-on-backdrop":true},on:{"ok":_vm.handleOk,"show":_vm.onReset}},[_c('loading',{attrs:{"active":_vm.isLoading,"loader":"spinner","color":"#20A8D8","is-full-page":false},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('b-form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('b-container',{attrs:{"fluid":""}},[_c('b-row',{staticClass:"my-2"},[_c('b-col',{attrs:{"lg":"12","md":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Company","label-class":"font-weight-bold pt-0"}},[_vm._v(" "+_vm._s(_vm.form.company)+" ")])],1),_c('b-col',{attrs:{"lg":"12","md":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Distribution Area","label-class":"font-weight-bold pt-0"}},[_vm._v(" "+_vm._s(_vm.form.connectedCompany + ' - ' + _vm.form.connectedStorageLocation)+" ")])],1),_c('b-col',{attrs:{"lg":"12","md":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Asset Type","label-class":"font-weight-bold pt-0"}},[_vm._v(" "+_vm._s(_vm.form.assetType)+" ")])],1),_c('b-col',{attrs:{"lg":"12","md":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Asset Total Adjustment","label-for":"Asset Total Adjustment","description":'Current Total: ' + _vm.form.total,"label-class":"font-weight-bold pt-0"}},[_c('b-form-input',{directives:[{name:"validate",rawName:"v-validate",value:({
								required: true,
								regex: /^([0-9-]{1,})$/,
							}),expression:"{\n\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\tregex: /^([0-9-]{1,})$/,\n\t\t\t\t\t\t\t}"}],attrs:{"id":"addTotal","name":"Asset Total Adjustment","type":"number","placeholder":"0"},model:{value:(_vm.form.addTotal),callback:function ($$v) {_vm.$set(_vm.form, "addTotal", $$v)},expression:"form.addTotal"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('Asset Total Adjustment')),expression:"errors.has('Asset Total Adjustment')"}],staticClass:"help-block"},[_vm._v(" "+_vm._s(_vm.errors.first('Asset Total Adjustment'))+" ")])],1),_c('div',{staticClass:"instruction mb-2"},[_vm._v(" You can increase the total count by entering a positive number (i.e. 1). You can decrease the total count by entering a negative number (i.e. -1) ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.form.addTotal && parseInt(_vm.form.addTotal) !== 0),expression:"form.addTotal && parseInt(form.addTotal) !== 0"}],staticClass:"adjusted-total mb-4"},[_vm._v(" Adjusted Total: "+_vm._s(_vm.adjustedTotal)+" ")])],1),_c('b-col',{attrs:{"lg":"12","md":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Notes","label-for":"Notes","label-class":"font-weight-bold pt-0"}},[_c('b-form-textarea',{directives:[{name:"validate",rawName:"v-validate",value:({
									required:true,
									regex: /^([0-9:;,.'\\\/()\-_a-zA-Z\n ])*$/
								}),expression:"{\n\t\t\t\t\t\t\t\t\trequired:true,\n\t\t\t\t\t\t\t\t\tregex: /^([0-9:;,.'\\\\\\/()\\-_a-zA-Z\\n ])*$/\n\t\t\t\t\t\t\t\t}"}],attrs:{"name":"Notes","type":"text","maxlength":"200","rows":3,"placeholder":"Notes"},model:{value:(_vm.form.notes),callback:function ($$v) {_vm.$set(_vm.form, "notes", $$v)},expression:"form.notes"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('Notes')),expression:"errors.has('Notes')"}],staticClass:"help-block"},[_vm._v(" "+_vm._s(_vm.errors.first('Notes'))+" ")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }